import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';


const LoginForm = ({ onSubmit }) => {

    return (
        <div className="_button-wrapper _full_width">

<p className="text-center">
                Dapatkan Bonus Spesial Ini, Jika Daftar VIP Waiting List Sebelum 30 Mei 2021
                - 3 Video Series dan Disc Up To 60% Early Bird Commitment Mastery
            </p>

            <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
            <input type="hidden" name="u" value="71" />
            <input type="hidden" name="f" value="71" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Nama Lengkap : </Form.Label>
                <Form.Control type="text" name="fullname" placeholder="Wajib" required/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Aktif:</Form.Label>
                <Form.Control type="email" name="email" placeholder="Wajib" required/>
            </Form.Group>
            <Button className="__color-outline btn-success" type="submit" size="lg" block >
                Submit
            </Button>
            </Form>

        </div>
    );
};


export default function Four(){

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const onLoginFormSubmit = (e) => {
      e.preventDefault();
      handleClose();
    };

    return (
        <>

           <Container fluid className="__bg-secondary-miror">
                <Container className="fade-in">
                    <Row>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="__image_happy_woman_plc">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/Ladingpage-plc/secret-3-image.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="__text-desc mt-3">
                                <h2 className="__text-headline __text-drak">
                                    Ini Dia Rahasia Agar Pria Mau Komit 
                                    Tanpa Paksaan Dan Tanpa Pertengkaran
                                </h2>
                                <p className="text-left">
                                    Commitment Mastery adalah sekuel Love Training yang dibawakan oleh ahlinya!
                                    Jose Aditya, seorang Pelatih Percintaan wanita #1 Indonesia
                                </p>
                                <p className="text-left">
                                    Dengan materi yang easy to learn, practical, and proven by the expert. KHUSUS untuk mereka yang ingin terhindar dari kehidupan romansa yang tak tentu arah dan tanpa kepastian.

                                </p>
                                <p className="text-left">
                                    Cari tahu cara menanam ide komitmen di otak pria tanpa terlihat ngebet nikah di Online Training ini!

                                </p>
                                <div className="__flex-mobile">
                                <Button variant="primary" size="lg"  onClick={handleShow}>
                                    JOIN WAITING LIST
                                </Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center __margin-my-wl">
                        <Col lg={12} sm={12} md={12} xs={12}>
                            <div className="__bg-small-primary-plc">
                                <p>
                                    Bagaimana jika Ada SATU PANDUAN yang Bisa Membuat Pria Mau Komit dengan Kamu Tanpa Paksaan dan Pertengkaran?

                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center __margin-my-wl">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="_mockup-am">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/Ladingpage-plc/Mockup-Device-CM.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="__text-desc _w-text-desc">
                                <h2 className="__text-headline __text-drak text-center">
                                    Perkenalkan Commitment Mastery
                                </h2>
                                <p className="text-center">
                                    Sebuah online training yang bisa kamu gunakan sebagai panduan untuk terhindar dari <br/>kehidupan romansa yang tak tentu arah, tanpa kepastian dan buat pria mau komit tanpa <br/>paksaan bahkan pertengkaran.
                                </p>
                                <p className="text-center">
                                    Bayangkan betapa nikmatnya bisa menanamkan benih komitmen di otak pria tanpa<br/> perlu nagih “kapan nikah?” dengan pasangan.
                                </p>
                                <p className="text-center">
                                    Bayangkan betapa nikmatnya menjalani hubungan minim pertengkaran karena kamu <br/> bisa bikin dia mau komit sama kamu dengan sukarela.
                                </p>
                                <p className="text-center">
                                    Commitment Mastery hanya diadakan 1 tahun sekali dan batch kedua akan dimulai <br/>tanggal 19 Juni  2021
                                </p>
                                <p className="text-center">
                                    Sebanyak 1.265 alumni kami mengaku amaze dengan teknik di Love Training ini serta <br/>merasakan perubahan dan manfaat dalam kehidupan romansanya.
                                </p>
                                
                                <div className="d-flex justify-content-center">
                                    <Button variant="primary" size="lg"  onClick={handleShow}>
                                            JOIN WAITING LIST
                                    </Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>


            <Modal 
            
            show={show} onHide={handleClose} 
            size="md"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="__text-wl-title">
                        Join Waiting List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="__modal-body-bg">
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                
            </Modal>

        </>
    )
}