import React from "react"
import Layout from "../components/layouts/layout_blank"
import SEO from "../components/seo/seo"
import SectionOne from "./section/s_waitinglist/s_one"
import SectionTwo from "./section/s_waitinglist/s_two"
import SectionFour from "./section/s_waitinglist/s_four"
import SectionFive from "./section/s_waitinglist/s_five"
import SectionTesti from "./section/s_waitinglist/s_testimoni"
import SectionEight from "./section/s_waitinglist/s_eight"
import SectionNine from "./section/s_waitinglist/s_nine"
import SectionPreefooter from "./section/s_waitinglist/tq-preefooter"

const IndexPage = () => (
  <Layout>
     <React.Fragment>
      <SEO title="Waiting List" />
          <SectionOne /> 
          <SectionTwo /> 
          <SectionFour /> 
          <SectionFive /> 
          <SectionTesti /> 
          <SectionEight /> 
          <SectionNine /> 
          <SectionPreefooter /> 
     </React.Fragment>
  </Layout> 
)

export default IndexPage
