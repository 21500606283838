import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Modal} from 'react-bootstrap';

const LoginForm = ({ onSubmit }) => {

    return (
        <div className="_button-wrapper _full_width">

            <p className="text-center">
                Dapatkan Bonus Spesial Ini, Jika Daftar VIP Waiting List Sebelum 30 Mei 2021
                - 3 Video Series dan Disc Up To 60% Early Bird Commitment Mastery
            </p>

            <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
            <input type="hidden" name="u" value="71" />
            <input type="hidden" name="f" value="71" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Nama Lengkap : </Form.Label>
                <Form.Control type="text" name="fullname" placeholder="Wajib" required/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Aktif:</Form.Label>
                <Form.Control type="email" name="email" placeholder="Wajib" required/>
            </Form.Group>
            <Button className="__color-outline btn-success" type="submit" size="lg" block >
                Submit
            </Button>
            </Form>

        </div>
    );
};

export default function Two(){
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const onLoginFormSubmit = (e) => {
      e.preventDefault();
      handleClose();
    };
    return (
        <>

            <Container fluid className="__bg-secondary_plc">
                <Container>
                    <Row className="fade-in _margin-middle">
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="__text-desc_plc">
                                <h2 className="__text-headline_plc __text-drak">
                                    Seberapa sering kamu berbisik dalam hati..
                                </h2>
                                <p>
                                   <i>“Udah PDKT lama tapi kenapa ya dia gak kunjung nembak?”<br/><br/>“Kira-kira dia niat serius apa cuma main-main aja ya?”</i> 
                                </p>
                                <p>
                                    Sementara sebagian dari kamu yang punya pasangan juga dilanda kecemasan...
                                </p>
                                <p>
                                    <i>“Kapan sih aku dilamar?”</i>
                                </p>
                                <p>
                                   <i>“Karir oke, umur cukup.. Kenapa dia masih menunda?”</i>
                                </p>
                                <p>
                                   <i>“Duh.. harus pacaran berapa lama lagi supaya diajak nikah?”</i>
                                </p>
                                <p>
                                    <i>“Atau mungkin.. bukan aku yang dia cari?”</i>
                                </p>
                                <p>
                                   <i>Akhirnya, semakin kamu bertanya, <br/>Semakin terasa pengorbanan selama ini jadi sia-sia.. hiks..</i>
                                </p>
                                <p>
                                    <i>“Ahh.. semua cowok sama aja!” <br/>“Kalau ditanya, kapan mau komit pasti jadi rumit..” huft T_T</i>
                                </p>
                                <p>
                                    Dan masih banyak lagi keresahan di hatimu yang sering muncul karena <b>kamu tidak tahu cara bikin pria mau komit tanpa paksaan dan pertengkaran.</b>
                                </p>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="__image_sad_woman_plc">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/Ladingpage-plc/image-sec-2.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="fade-in">
                        <div className="col-lg-12">
                            <div className="__text-join-plc-cm">
                                <h2>Join VIP Waiting List Commitment Mastery Sebelum 30 Mei 2021!</h2>
                                <p>Untuk dapatkan BONUS 3 video series Commitment Mastery <br/> dan Diskon 60% Tiket VIP Commitment Mastery </p>
                                <div className="__text-desc d-flex justify-content-center">
                                    <div className="__flex-mobile">
                                        <Button variant="primary" size="lg" onClick={handleShow}>
                                            JOIN WAITING LIST
                                        </Button>{' '}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </Container>
            <Modal 
            
            show={show} onHide={handleClose} 
            size="md"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="__text-wl-title">
                        Join Waiting List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="__modal-body-bg">
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                
            </Modal>
        </>
    )
}