import React, { useState } from "react";
import { Container, Row, Col, Button, Form, Modal  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'


const LoginForm = ({ onSubmit }) => {

    return (
        <div className="_button-wrapper _full_width">

            <p className="text-center">
                Dapatkan Bonus Spesial Ini, Jika Daftar VIP Waiting List Sebelum 30 Mei 2021
                - 3 Video Series dan Disc Up To 60% Early Bird Commitment Mastery
            </p>

            <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
            <input type="hidden" name="u" value="71" />
            <input type="hidden" name="f" value="71" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Nama Lengkap : </Form.Label>
                <Form.Control type="text" name="fullname" placeholder="Wajib" required/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Aktif:</Form.Label>
                <Form.Control type="email" name="email" placeholder="Wajib" required/>
            </Form.Group>
            <Button className="__color-outline btn-success" type="submit" size="lg" block >
                Submit
            </Button>
            </Form>

        </div>
    );
};



export default function Five(){

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const onLoginFormSubmit = (e) => {
      e.preventDefault();
      handleClose();
    };
    return (
        <>

           <Container fluid className="__bg-primary-miror-v2-plc">
                <Container className="fade-in">
                    <Row className="justify-content-center">
                        <h2 className="__text-headline text-white text-center  __margin-bottom-5-wl">
                            Inilah yang akan kamu dapatkan setelah gabung VIP Waiting List Commitment Mastery

                        </h2>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="__image_log_v2">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/Ladingpage-plc/Logo-CM.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="__bg-white-rounded-plc">
                                <Row>
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                    <div className="d-flex justify-content-end pt-1">
                                        <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle"/>
                                    </div>
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                        <p className="__text-desc-icon"><b>Secret #1</b> Agar Pria Senang Hati Mau Komit </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                    <div className="d-flex justify-content-end">
                                        <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle"/>
                                    </div>
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                        <p className="__text-desc-icon"><b>Secret #2</b> Apa yang Membuat Pria Mau Komit? </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                    <div className="d-flex justify-content-end">
                                        <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle"/>
                                    </div>
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                        <p className="__text-desc-icon"><b>Secret #3</b> Commitment Formula</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={1} md={1} sm={1} xs={1}>
                                    <div className="d-flex justify-content-end">
                                        <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle"/>
                                    </div>
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                        <p className="__text-desc-icon">Diskon 60% untuk Tiket VIP Online Training Commitment Mastery 19 Juni 2021</p>
                                    </Col>
                                </Row>
                                
                            </div>
                            <div className="__text-desc _w-text-desc mt-5">
                                <div className="d-flex justify-content-center">
                                    
                                        <Button variant="primary" size="lg" onClick={handleShow}>
                                            JOIN WAITING LIST
                                        </Button>{' '}
                                  
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Modal 
            
            show={show} onHide={handleClose} 
            size="md"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="__text-wl-title">
                        Join Waiting List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="__modal-body-bg">
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                
            </Modal>

        </>
    )
}